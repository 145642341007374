<template>
  <div>
    <div class="productEditContainer ignore_view">
      <div class="mt40 mb25">
        <div
          class="d-flex bgwhite backBtn j-center userSelectNone a-center commontransition cursorPointer primaryHover"
          @click="goToMyProduct"
        >
          Back
        </div>
      </div>
      <a-form
        :form="form"
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
      >
        <div v-for="(uploadProductItem, index) in uploadProductItems" :key="uploadProductItem.blankProduct.model + index" class="d-flex">
          <div>
            <div class="leftContainer mr40 bgwhite p24" @click="editSelectProduct(index)">
              <div class="fontBold">
                {{ uploadProductItem.blankProduct.model }}
              </div>
              <img
                class="productImg mt24"
                :src="uploadProductItem.blankProduct.mainShowImageUrl"
              >
            </div>
          </div>
          <div class="rightContainer bgwhite p24 mb15">
            <div class="font20 fontBold">Submit Designs Images</div>

            <div>
              <div class="fontBold mb30 mt30">{{ uploadProductItem.blankProduct.model }}</div>

              <div>
                <div name="label " class="mb10">Print File <span class="colorFD051D">*</span></div>
                <div class="uploadBox">
                  <multi-image-upload placeholder="Click to upload a file" :image-urls.sync="uploadProductItem.printImageList" :max-image-num="5" />
                </div>
              </div>

              <div name="label" class="mt20 mb10">
                Display Mockup
                <span class="colorFD051D">*</span>
              </div>
              <div class="uploadBox">
                <multi-image-upload placeholder="Click to upload files. (Up to 5 files)" :image-urls.sync="uploadProductItem.effectImageList" :max-image-num="5" />
              </div>
              <div name="label" class="mt20 mb10">Note</div>
              <a-input
                v-model="uploadProductItem.note"
                placeholder="Enter a note"
              />
              <div class="colorgraylightColor mt10">
                If you have specific requirement on template,please leave us a note here.
              </div>
            </div>
          </div>
        </div>
        <div class="rightContainer bgwhite p24 saveView ml-auto">
          <div
            class="colorprimary addProductBtn d-flex j-center a-center mt10 commontransition commonHover cursorPointer"
            @click="addNewProduct"
          >
            + Add A New Product
          </div>
          <div>
            <div name="label" class="pt28 pb14">Email</div>
            <a-form-item>
              <a-input
                v-model="noteEmail"
                type="email"
                :max-length="100"
                placeholder="Enter your email here."
              />
            </a-form-item>
            <div class="colorgraylightColor mt10">
              Please leave us your email here for furture contact.
            </div>
          </div>
          <a-form-item>
            <div class="d-flex">
              <a-button
                type="primary"
                :loading="batchUploadProductLoading"
                class="saveBtn ml-auto"
                @click="handleSubmit"
              >Save</a-button>
            </div>
          </a-form-item>
        </div>
      </a-form>
      <select-product-modal
        ref="selectProductModal"
        @select-product="doSelectProduct"
      />
    </div>
  </div>

</template>

<script>
import SelectProductModal from './components/select_product_modal.vue'
import MultiImageUpload from '@/components/multi_image_upload'
import { batchUploadCustomProduct, productDetail } from '@/api/product'
export default {
  name: 'UploadProduct',
  components: {
    SelectProductModal,
    MultiImageUpload
  },
  props: {
    blankProductId: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      form: undefined,
      noteEmail: '',
      uploadProductItems: [],
      toSelectProductIndex: null,
      batchUploadProductLoading: false
    }
  },
  mounted() {
    this.form = this.$form.createForm(this, { name: 'coordinated' })
    if (this.blankProductId) {
      this.initSelectProduct()
    }
  },
  methods: {
    async initSelectProduct() {
      var response = await productDetail({ id: this.blankProductId, blankProductInfo: true })
      if (response.code === 200) {
        var productInfo = response.data.blankProductInfo
        productInfo.id = response.data.id
        productInfo.mainShowImageUrl = productInfo.mainImageUrl
        this.doSelectProduct(productInfo)
      }
    },
    addNewProduct() {
      this.toSelectProductIndex = null
      this.$refs.selectProductModal.show()
    },
    editSelectProduct(index) {
      this.toSelectProductIndex = index
      this.$refs.selectProductModal.show()
    },
    async handleSubmit() {
      this.batchUploadProductLoading = true
      try {
        if (!this.uploadProductItems || this.uploadProductItems.length === 0) {
          this.$message.error('Please select product')
          return
        }
        for (const uploadProductItem of this.uploadProductItems) {
          if (uploadProductItem.printImageList.length === 0) {
            this.$message.error(`Please upload image`)
            return
          }
          if (uploadProductItem.effectImageList.length === 0) {
            this.$message.error(`Please upload mockup image`)
            return
          }
        }
        var records = []
        for (const uploadProductItem of this.uploadProductItems) {
          var record = {}
          record.note = uploadProductItem.note
          record.blankProductId = uploadProductItem.blankProduct.id
          record.printImageList = uploadProductItem.printImageList
          record.effectImageList = uploadProductItem.effectImageList
          records.push(record)
        }
        var postData = {}
        postData.noteEmail = this.noteEmail
        postData.records = records
        const response = await batchUploadCustomProduct(postData)
        if (response.code === 200) {
          this.$message.success('Success')
          this.goToMyProduct()
        } else {
          this.$message.error(response.msg)
        }
      } finally {
        this.batchUploadProductLoading = false
      }
    },
    doSelectProduct(v) {
      if (this.toSelectProductIndex === null) {
        const newUploadProductItem = {}
        newUploadProductItem.blankProduct = v
        newUploadProductItem.printImageList = []
        newUploadProductItem.effectImageList = []
        newUploadProductItem.note = ''
        this.uploadProductItems.push(newUploadProductItem)
      } else {
        var uploadProductItem = this.uploadProductItems[this.toSelectProductIndex]
        uploadProductItem.blankProduct = v
      }
    },
    goToMyProduct() {
      this.callNewPage(`/my-product`)
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../assets/less/theme.less";
.productEditContainer {
  width: @pageWidth;
  margin: 0 auto;
  .uploadBox{
    overflow: hidden;
     border: 1px dashed #4cbbf9;
      border-radius: 10px;
  }
  .pt28 {
    padding-top: 28px;
  }
  .pb14 {
    padding-bottom: 14px;
  }
  .backBtn {
    width: 77px;
    height: 40px;
    border-radius: 8px;
  }
  .leftContainer {
    width: 331px;
    border-radius: 12px;
    top: 80px;
    .productImg {
      width: 261px;
      height: 261px;
      object-fit: cover;
      border-radius: 12px;
    }
  }
  .rightContainer {
    border-radius: 12px;
    width: calc(100% - 371px);
    .colorFD051D {
      color: #fd051d;
    }
    .addProductBtn {
      width: 500px;
      height: 44px;
      background: #eff8ff;
      border-radius: 8px;
    }
    .imguploadItem {
      .uploadedimg {
        width: 72px;
        height: 72px;
        border-radius: 8px;
      }
      .colorIcon {
        width: 20px;
        height: 20px;
        right: 0;
        top: 0;
        transform: translate(50%, -50%);
      }
    }
    .saveBtn {
      width: 140px;
      height: 44px;
    }
    .uploadBtn {
      width: 981px;
      background: #f2f6ff;
      border: 1px dashed #4cbbf9;
      border-radius: 10px;
      .uploadicon {
        width: 50px;
        height: 50px;
      }
    }
  }
  .saveView {
    width: calc(100% - 371px);
  }
}
</style>
